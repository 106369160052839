<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/paper/list/${$route.query.bankId}` }">试卷列表</el-breadcrumb-item>
        <el-breadcrumb-item>自动出卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container yt-container-flex left-has-menu">
      <div class="yt-content-tree">
        <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
          <el-menu-item index="0">
            <div class="icon">
              <YTIcon :href="'#icon-lingdaitie3'" style="height: 12px"></YTIcon>
            </div>
            按岗位选择
          </el-menu-item>
          <el-menu-item index="1">
            <div class="icon">
              <YTIcon :href="'#icon-biaoqian'"></YTIcon>
            </div>
            按技能选择
          </el-menu-item>
        </el-menu>
        <div class="search-wrapper">
          <el-input
            v-model="keyword"
            class="yt-search search"
            :placeholder="`通过${activeIndex === '0' ? '岗位' : '技能'}搜索`"
            @keydown.enter.native="toFilterNode"
          >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode"></i>
          </el-input>
          <template v-if="activeIndex === '1'">
            <YTIcon :href="`#icon-liebiao${activeAbilityIndex ? 2 : 1}`" @click="switchAbilityMode"></YTIcon>
          </template>
        </div>
        <div class="tree has-tool" style="height: calc(100% - 101px); margin-top: 0">
          <el-tree
            :data="treeData"
            :filter-node-method="filterNode"
            node-key="id"
            ref="tree"
            :highlight-current="true"
            @node-click="modeFunctions[activeIndex].handleClickTreeNode"
          >
            <div class="tree-node" slot-scope="{ node, data }">
              <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                <div class="el-tree-node__label">{{ node.label }}</div>
              </el-tooltip>
              <div style="margin-right: 12px">{{ data.questionCount }}</div>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="yt-content">
        <!--右侧是否有选中, 没有显示缺省-->
        <template v-if="!isActiveEmpty">
          <template v-if="setMode !== 'POST'">
            <div class="knowledge-list-wrapper" :class="{ active: isPackUp }">
              <div class="knowledge-list" ref="knowledgeList" :class="{ active: isPackUp }">
                <template v-for="(knowledge, index) in knowledgeList">
                  <div
                    class="item"
                    :class="{
                      active: knowledgeSelection.includes(knowledge.knowledgeId)
                    }"
                    @click="selectKnowledge(knowledge.knowledgeId)"
                    :key="index"
                  >
                    {{ knowledge.name }}
                    <div v-if="knowledgeSelection.includes(knowledge.knowledgeId)" class="checked">
                      <div class="triangle"></div>
                      <YTIcon :href="'#icon-gou'"></YTIcon>
                    </div>
                  </div>
                </template>
              </div>
              <!--展开收起按钮-->
              <div class="pack-up-button" :class="{ active: isPackUp }">
                <YTIcon :href="'#icon-open'" @click="isPackUp = !isPackUp"></YTIcon>
                <YTIcon :href="'#icon-open'" style="margin-top: -10px" @click="toPackUp"></YTIcon>
              </div>
            </div>
          </template>
          <div class="generation-container" :class="{ 'has-knowledge': setMode !== 'POST' }">
            <div class="ability-form">
              <div class="ability-header">
                <p>
                  {{ activeName.label }}名称:
                  <el-tooltip effect="dark" :content="activeName.value" placement="top-start">
                    <span>{{ activeName.value }}</span>
                  </el-tooltip>
                </p>
              </div>
              <el-form ref="form" :rules="ruleValidate" :model="activeForm" class="yt-form" label-width="106px">
                <el-form-item label="题目数量" prop="questionCount">
                  <el-input-number
                    v-model="activeForm.questionCount"
                    :disabled="
                      setMode === 'POST_ABILITY' ||
                        (setMode === 'ABILITY' && activeForm.mode === 2) ||
                        (setMode === 'ABILITY' && codeEnum.includes(activeAbility.label))
                    "
                    :controls="false"
                    :precision="0"
                    :min="0"
                    placeholder="请输入题目数量"
                  />
                </el-form-item>
                <el-form-item label="技能难度" prop="difficulty">
                  <el-select v-model="activeForm.difficulty" :disabled="setMode === 'POST_ABILITY'" placeholder="请选择">
                    <el-option
                      v-for="item in this.ytConstant.paperDifficultyType.getMenu()"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="选择题目方式" class="form-item-mode">
                  <el-radio-group v-model="activeForm.mode" :disabled="setMode === 'POST_ABILITY'" @change="switchMode">
                    <el-radio :label="modeEnum.AUTO">
                      算法自动选择
                      <el-tooltip effect="dark" content="算法会控制单选、多选、判断、填空的比例。代码题的数量请手动输入。" placement="top">
                        <YTIcon :href="'#icon-tishi'"></YTIcon>
                      </el-tooltip>
                    </el-radio>
                    <el-radio :label="modeEnum.ALLOCATED">
                      设置{{ setMode === 'POST' ? '题型权重' : '题目数量' }}
                      <el-tooltip
                        effect="dark"
                        :content="
                          setMode === 'POST'
                            ? '系统会根据输入的题目权重计算题目的比例再分配至各个技能，具体题目数量可能会有轻微偏差，对总题数不会有影响。'
                            : '手动控制技能下各题型数量'
                        "
                        placement="top"
                      >
                        <YTIcon :href="'#icon-tishi'"></YTIcon>
                      </el-tooltip>
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="showQuestionTypes">
                  <el-form-item label="判断题" prop="trueOrFalse">
                    <el-input-number v-model="activeForm.trueOrFalse" :controls="false" :max="setMode === 'POST' ? 999 : Infinity" :min="0" />
                    <template v-if="setMode !== 'POST'">
                      <span>{{ questionTypeNum.trueOrFalseNum }}</span>
                      <el-tooltip effect="dark" content="判断题总数" placement="top">
                        <YTIcon :href="'#icon-tishi'"></YTIcon>
                      </el-tooltip>
                    </template>
                    <template v-if="setMode === 'POST_ABILITY' && normalError.trueOrFalse">
                      <div class="supply-tip">技能下题目数量不足，创建试卷时补充题目</div>
                    </template>
                  </el-form-item>
                  <el-form-item label="单选题" prop="singleChoice">
                    <el-input-number v-model="activeForm.singleChoice" :controls="false" :max="setMode === 'POST' ? 999 : Infinity" :min="0" />
                    <template v-if="setMode !== 'POST'">
                      <span>{{ questionTypeNum.singleChoiceNum }}</span>
                      <el-tooltip effect="dark" content="单选题总数" placement="top">
                        <YTIcon :href="'#icon-tishi'"></YTIcon>
                      </el-tooltip>
                    </template>
                    <template v-if="setMode === 'POST_ABILITY' && normalError.singleChoice">
                      <div class="supply-tip">技能下题目数量不足，创建试卷时补充题目</div>
                    </template>
                  </el-form-item>
                  <el-form-item label="多选题" prop="multipleChoice">
                    <el-input-number v-model="activeForm.multipleChoice" :controls="false" :max="setMode === 'POST' ? 999 : Infinity" :min="0" />
                    <template v-if="setMode !== 'POST'">
                      <span>{{ questionTypeNum.multipleChoiceNum }}</span>
                      <el-tooltip effect="dark" content="多选题总数" placement="top">
                        <YTIcon :href="'#icon-tishi'"></YTIcon>
                      </el-tooltip>
                    </template>
                    <template v-if="setMode === 'POST_ABILITY' && normalError.multipleChoice">
                      <div class="supply-tip">技能下题目数量不足，创建试卷时补充题目</div>
                    </template>
                  </el-form-item>
                  <el-form-item label="填空题" prop="fillInBlank">
                    <el-input-number v-model="activeForm.fillInBlank" :controls="false" :max="setMode === 'POST' ? 999 : Infinity" :min="0" />
                    <template v-if="setMode !== 'POST'">
                      <span>{{ questionTypeNum.fillInBlankNum }}</span>
                      <el-tooltip effect="dark" content="填空题总数" placement="top">
                        <YTIcon :href="'#icon-tishi'"></YTIcon>
                      </el-tooltip>
                    </template>
                    <template v-if="setMode === 'POST_ABILITY' && normalError.fillInBlank">
                      <div class="supply-tip">技能下题目数量不足，创建试卷时补充题目</div>
                    </template>
                  </el-form-item>
                </template>
                <template v-if="showCode">
                  <el-form-item label="代码题" prop="code" :class="{ 'code-border': setMode === 'POST' && activeForm.mode === modeEnum.ALLOCATED }">
                    <el-input-number
                      v-model="activeForm.code"
                      :controls="false"
                      :max="setMode === 'POST' ? 999 : Infinity"
                      :min="0"
                      :precision="0"
                      :disabled="hasCode"
                    />
                    <span>{{ questionTypeNum.codeNum }}</span>
                    <el-tooltip effect="dark" content="代码题总数" placement="top">
                      <YTIcon :href="'#icon-tishi'"></YTIcon>
                    </el-tooltip>
                  </el-form-item>
                </template>
              </el-form>
              <div class="confirm-button">
                <el-button v-if="setMode === 'POST'" size="small" type="primary" @click="addPost">确定岗位出卷设置</el-button>
                <el-button v-else size="small" type="primary" @click="modeFunctions[activeIndex].addAbility">确定技能设置</el-button>
              </div>
            </div>
            <div class="ability-cart">
              <div class="ability-header">
                <p>已选技能</p>
                <p>
                  已选题目:
                  <span style="color: #FF5050">{{ totalQuestionCount }}</span>
                </p>
              </div>
              <ul class="ability-selection">
                <li
                  v-for="(item, index) in abilitySelection"
                  :key="index"
                  @click="modeFunctions[activeIndex].checkAbility(item)"
                  :class="{ active: isAbilityActive(item) }"
                  class="item"
                >
                  <div style="margin-bottom: 10px;">
                    <el-tooltip effect="dark" :content="item.abilityName" placement="top-start">
                      <p class="title">{{ item.abilityName }}</p>
                    </el-tooltip>
                    <p style="display: flex; align-items: center">
                      <el-tooltip v-if="item.hasOwnProperty('mainAbilityName')" effect="dark" :content="item.mainAbilityName" placement="top-start">
                        <span class="textEllipsis">{{ item.mainAbilityName }}</span>
                      </el-tooltip>
                      <template v-if="activeIndex === '1'">
                        <YTIcon :href="'#icon-shanchu'" @click="deleteAbility(index, item.abilityName)"></YTIcon>
                      </template>
                    </p>
                  </div>
                  <div>
                    <span>难度: {{ ytConstant.paperDifficultyType.getLabel(item.difficulty) }}</span>
                    <span>预选题: {{ item.questionCount }}</span>
                  </div>
                </li>
              </ul>
              <div class="confirm-button">
                <el-button size="small" type="primary" :loading="createLoading" @click="createTest">创建试卷</el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="empty-container">
            <YTIcon :href="'#icon-zanwushuju'" style="font-size: 150px"></YTIcon>
            <p style="margin-top: 5px">
              请从左侧列表中选择<span @click="selectMenu('0')">岗位</span>或<span @click="selectMenu('1')">技能</span>创建试卷
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import questionApi from '@api/question'
import ability from '@api/ability'
import autoQuestionApi from '@api/autoQuestion'
import { mapGetters, mapMutations } from 'vuex'
import newPostApi from '@api/newPost'
export default {
  name: 'PaperAutoGeneration',
  components: { YTIcon },
  data() {
    const checkTrueOrFalse = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目数量不能为空'))
      }
      if (this.setMode === 'POST_ABILITY') {
        this.normalError.trueOrFalse = false
      }
      if (this.setMode !== 'POST' && value > this.questionTypeNum.trueOrFalseNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        if (this.setMode !== 'POST') {
          this.activeForm.questionCount = this.computedQuestionCount()
        }
        callback()
      }
    }
    const checkSingleChoice = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目数量不能为空'))
      }
      if (this.setMode === 'POST_ABILITY') {
        this.normalError.singleChoice = false
      }
      if (this.setMode !== 'POST' && value > this.questionTypeNum.singleChoiceNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        if (this.setMode !== 'POST') {
          this.activeForm.questionCount = this.computedQuestionCount()
        }
        callback()
      }
    }
    const checkMultipleChoice = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目数量不能为空'))
      }
      if (this.setMode === 'POST_ABILITY') {
        this.normalError.multipleChoice = false
      }
      if (this.setMode !== 'POST' && value > this.questionTypeNum.multipleChoiceNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        if (this.setMode !== 'POST') {
          this.activeForm.questionCount = this.computedQuestionCount()
        }
        callback()
      }
    }
    const checkFillInBlank = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目数量不能为空'))
      }
      if (this.setMode === 'POST_ABILITY') {
        this.normalError.fillInBlank = false
      }
      if (this.setMode !== 'POST' && value > this.questionTypeNum.fillInBlankNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        if (this.setMode !== 'POST') {
          this.activeForm.questionCount = this.computedQuestionCount()
        }
        callback()
      }
    }
    const checkCode = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('题目数量不能为空'))
      }
      if (this.setMode === 'POST' && value > this.activeForm.questionCount) {
        return callback(new Error('代码题数量不能超出题目数量'))
      }
      if (value > this.questionTypeNum.codeNum) {
        callback(new Error('题目数量不能超出范围'))
      } else {
        if (this.setMode !== 'POST') {
          this.activeForm.questionCount = value
        }
        callback()
      }
    }
    const checkQuestionCount = (rule, value, callback) => {
      if (this.showCode) {
        this.$refs.form.validateField('code')
      }
      if (this.setMode === 'POST' && value > this.activePost.questionCount) {
        callback(new Error('题目数量不能超出范围'))
      } else if (this.setMode === 'ABILITY' && value > this.activeAbility.questionCount) {
        callback(new Error('题目数量不能超出范围'))
      } else if (this.activeForm.mode === this.modeEnum.AUTO && value === 0) {
        callback(new Error('题目数量不能为0'))
      } else {
        callback()
      }
    }
    return {
      activeIndex: '0', //左侧模式
      activeAbilityIndex: true, //左侧技能模式
      treeData: [], //左侧数据
      postData: [], //岗位
      postAbilityData: [], //岗位技能
      abilityData: [], //技能
      keyword: '', //搜索
      isPackUp: false, //知识点收起
      activePost: {}, //当前岗位
      activePostAbility: {}, //当前岗位右侧点击的技能
      activeAbility: {
        name: '',
        description: '',
        questionCount: 0
      },
      activeName: { label: '岗位', value: '' }, //标题名称
      activeForm: {}, //当前表单
      autoForm: {}, //算法表单
      allocatedForm: {}, //手动设置表单
      defaultForm: {
        difficulty: '',
        trueOrFalse: 0,
        singleChoice: 0,
        multipleChoice: 0,
        fillInBlank: 0,
        code: 0,
        questionCount: 0
      }, //默认表单
      ruleValidate: {
        difficulty: [{ required: true, message: '请选择技能难度', trigger: 'change' }],
        trueOrFalse: [{ validator: checkTrueOrFalse, trigger: 'blur' }],
        singleChoice: [{ validator: checkSingleChoice, trigger: 'blur' }],
        multipleChoice: [{ validator: checkMultipleChoice, trigger: 'blur' }],
        fillInBlank: [{ validator: checkFillInBlank, trigger: 'blur' }],
        code: [{ validator: checkCode, trigger: 'blur' }],
        questionCount: [
          { required: true, message: '题目数量不能为空', trigger: 'blur' },
          { validator: checkQuestionCount, trigger: 'blur' }
        ]
      },
      modeEnum: {
        AUTO: 1,
        ALLOCATED: 2
      }, //出题模式枚举
      normalError: {
        trueOrFalse: false,
        singleChoice: false,
        multipleChoice: false,
        fillInBlank: false
      },
      knowledgeList: [], //知识点列表
      knowledgeSelection: [], //勾选知识点
      abilitySelection: [], //右侧技能列表
      modeFunctions: {
        '0': {
          handleClickTreeNode: this.handleClickPostTreeNode,
          addAbility: this.updatePostAbility,
          checkAbility: this.checkPostAbility
        },
        '1': {
          handleClickTreeNode: this.handleClickAbilityTreeNode,
          addAbility: this.addAbility,
          checkAbility: this.checkAbility
        }
      }, //不同模式下执行的事件
      questionTypeNum: {
        codeNum: 0,
        fillInBlankNum: 0,
        multipleChoiceNum: 0,
        singleChoiceNum: 0,
        subjectiveNum: 0,
        totalNum: 0,
        trueOrFalseNum: 0
      },
      codeEnum: ['编程操作'],
      createLoading: false //创建试卷防抖
    }
  },
  computed: {
    ...mapGetters('question', ['getEditorData']),
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    },
    isActiveEmpty() {
      if (this.activeIndex === '0') {
        return Object.keys(this.activePost).length === 0
      } else {
        return Object.keys(this.activeAbility).length === 0
      }
    },
    setMode() {
      //判断设置模式: 岗位, 岗位下技能, 技能
      if (this.activeIndex === '0') {
        return Object.keys(this.activePostAbility).length === 0 ? 'POST' : 'POST_ABILITY'
      } else {
        return 'ABILITY'
      }
    },
    showQuestionTypes() {
      //判断是否显示除代码题外的题型
      let res = this.activeForm.mode === 2
      if (this.setMode !== 'POST') {
        if (this.setMode === 'POST_ABILITY') {
          return !this.codeEnum.includes(this.activePostAbility.abilityName)
        } else {
          return res && !this.codeEnum.includes(this.activeAbility.label)
        }
      } else {
        return res
      }
    },
    showCode() {
      //判断是否显示代码题
      if (this.setMode === 'POST_ABILITY') {
        return this.codeEnum.includes(this.activePostAbility.abilityName)
      } else if (this.setMode === 'ABILITY') {
        return this.codeEnum.includes(this.activeAbility.label)
      }
      return this.setMode === 'POST'
    },
    hasCode() {
      //判断是否可以填代码题数量
      if (this.setMode === 'POST') {
        return !this.activePost.hasCode
      } else {
        return false
      }
    },
    totalQuestionCount() {
      //右侧技能下题目总数
      return this.abilitySelection.reduce((prev, cur) => {
        return prev + cur.questionCount
      }, 0)
    },
    isAbilityActive() {
      //右侧技能选中
      if (this.setMode === 'POST_ABILITY') {
        return data => {
          let res = this.activePostAbility.abilityId === data.abilityId
          if (data.hasOwnProperty('mainAbilityName')) {
            return res && this.activePostAbility.mainAbilityName === data.mainAbilityName
          }
          return res
        }
      } else {
        return data => {
          return this.activeAbility.id === data.abilityId
        }
      }
    }
  },
  created() {
    this.getPostList()
    this.getAbilityList()
  },
  methods: {
    ...mapMutations('question', ['handleSelectAll', 'setEditorData']),
    //data
    getPostList() {
      //获取岗位以及其下技能的题目数量
      newPostApi.getPostAbilityQuestion().then(res => {
        //岗位列表
        this.postData = res.res.map(item => {
          return {
            id: item.postId,
            label: item.postName,
            questionCount: item.questionCount,
            codeQuestionCount: item.codeQuestionCount,
            form: {
              difficulty: '',
              mode: this.modeEnum.AUTO,
              trueOrFalse: 0,
              singleChoice: 0,
              multipleChoice: 0,
              fillInBlank: 0,
              code: 0,
              questionCount: 0
            }, //提交表单
            abilityList: [] //技能设置
          }
        })
        this.treeData = this.postData
        //岗位技能列表
        this.postAbilityData = res.res.map(item => {
          return {
            id: item.postId,
            label: item.postName,
            questionCount: item.questionCount,
            children: item.abilityQuestionCountS.map(ability => {
              return {
                id: ability.abilityId,
                label: ability.abilityName,
                questionCount: ability.questionCount,
                children:
                  ability.subAbilityQuestionCountS === null
                    ? []
                    : ability.subAbilityQuestionCountS.map(subAbility => {
                        return {
                          id: subAbility.abilityId,
                          label: subAbility.abilityName,
                          questionCount: subAbility.questionCount
                        }
                      })
              }
            })
          }
        })
      })
    },
    checkCodeCount(id) {
      //查询岗位下是否有代码题
      newPostApi.judgeCodeQuestion(id).then(res => {
        this.$set(this.activePost, 'hasCode', res.res)
      })
    },
    getKnowledgeSimple(abilityId) {
      //获取技能下知识点
      ability.getKnowledgeSimple(abilityId).then(res => {
        this.knowledgeList = res.res
        if (this.setMode === 'ABILITY') {
          this.knowledgeSelection = []
          let data = this.abilityData.filter(item => {
            return item.id === abilityId
          })[0]
          if (!data.hasOwnProperty('knowledgeIds')) {
            for (let i = 0; i < this.knowledgeList.length; i++) {
              this.knowledgeSelection.push(this.knowledgeList[i].knowledgeId)
            }
            data.knowledgeIds = this.knowledgeSelection
          } else {
            this.knowledgeSelection = this.$deepCopy(data.knowledgeIds)
          }
        }
        this.getQuestionTypeCount()
      })
    },
    getQuestionTypeCount() {
      //获取勾选的知识点下题目数量
      questionApi.getQuestionTypeCount(this.knowledgeSelection).then(res => {
        this.questionTypeNum = res.res
        //验证补题提示
        this.normalError.trueOrFalse = this.activeForm.trueOrFalse > this.questionTypeNum.trueOrFalseNum
        this.normalError.singleChoice = this.activeForm.singleChoice > this.questionTypeNum.singleChoiceNum
        this.normalError.multipleChoice = this.activeForm.multipleChoice > this.questionTypeNum.multipleChoiceNum
        this.normalError.fillInBlank = this.activeForm.fillInBlank > this.questionTypeNum.fillInBlankNum
      })
    },
    getAbilityList() {
      //获取技能树
      ability.getAbilityQuestionCount().then(res => {
        this.abilityData = res.res.map(item => {
          return {
            id: item.id,
            label: item.name,
            questionCount: item.questionCount,
            isSelected: false,
            form: {
              difficulty: '',
              mode: this.modeEnum.AUTO,
              trueOrFalse: 0,
              singleChoice: 0,
              multipleChoice: 0,
              fillInBlank: 0,
              code: 0,
              questionCount: 0
            } //提交表单
          }
        })
      })
    },
    //events
    selectMenu(index) {
      this.activeIndex = index
      this.treeData = []
      this.resetMenu()
      if (this.activeIndex === '0') {
        this.treeData = this.postData
      } else {
        this.activeAbilityIndex = true //岗位技能模式
        this.treeData = this.postAbilityData
        this.abilitySelection = this.abilityData
          .filter(item => {
            return item.isSelected
          })
          .map(item => {
            let payload = {
              abilityId: item.id,
              abilityName: item.label,
              difficulty: item.form.difficulty,
              knowledgeIds: item.knowledgeIds,
              questionCount: item.form.questionCount,
              mode: item.form.mode,
              typeQuestionCount: {}
            }
            if (this.showCode) {
              payload.typeQuestionCount = {
                '5': item.form.code
              }
            } else {
              payload.typeQuestionCount = {
                '0': item.form.trueOrFalse,
                '1': item.form.singleChoice,
                '2': item.form.multipleChoice,
                '3': item.form.fillInBlank
              }
            }
            return payload
          })
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    toFilterNode(ref) {
      this.$refs.tree.filter(this.keyword)
      this.$refs.tree.setCheckedNodes([])
    },
    switchAbilityMode() {
      //切换技能树显示模式
      this.activeAbilityIndex = !this.activeAbilityIndex
      this.treeData = this.activeAbilityIndex ? this.postAbilityData : this.abilityData
    },
    handleClickPostTreeNode(data) {
      //岗位树节点点击
      this.activeForm = data.form //当前表单
      this.activePostAbility = {} //取消右侧技能选择
      this.activePost = data //当前岗位
      this.activeName.label = '岗位' //当前标题
      this.activeName.value = data.label //当前标题
      this.questionTypeNum.codeNum = data.codeQuestionCount //代码题数量
      this.checkCodeCount(data.id)
      this.$nextTick(() => {
        this.resetForm() //重置暂存表单
        this.$refs.form.clearValidate()
        this.abilitySelection = data.abilityList
      })
    },
    handleClickAbilityTreeNode(data, node) {
      if (this.activeAbilityIndex && node.level < 2) {
        return
      }
      //技能树节点点击
      this.activeName.label = '技能' //当前标题
      this.activeAbility = data
      this.activeForm = this.abilityData.filter(item => {
        return item.id === data.id
      })[0].form //当前表单
      this.activeName.value = data.label //当前标题
      this.getKnowledgeSimple(data.id)
      this.$nextTick(() => {
        this.resetForm() //重置暂存表单
        this.$refs.form.clearValidate()
      })
    },
    toPackUp() {
      this.isPackUp = !this.isPackUp
      this.$refs.knowledgeList.scrollTop = 0
    },
    selectKnowledge(id) {
      //知识点勾选
      if (this.knowledgeSelection.includes(id)) {
        this.knowledgeSelection.splice(this.knowledgeSelection.indexOf(id), 1)
      } else {
        this.knowledgeSelection.push(id)
      }
      this.getQuestionTypeCount()
    },
    switchMode(value) {
      //切换出题模式和表单变量,点击设置按钮前不重置右侧技能列表
      if (value === 2) {
        this.autoForm = this.$deepCopy({ ...this.activeForm, mode: this.modeEnum.AUTO })
        this.activeForm = this.$deepCopy(this.allocatedForm)
      } else {
        this.allocatedForm = this.$deepCopy({ ...this.activeForm, mode: this.modeEnum.ALLOCATED })
        this.activeForm = this.$deepCopy(this.autoForm)
      }
      this.$refs.form.resetFields()
    },
    addPost() {
      //添加岗位或岗位下技能设置
      this.$refs.form.validate(valid => {
        if (valid) {
          let payload = {
            codeQuestionCount: this.activeForm.code,
            difficulty: this.activeForm.difficulty,
            postId: this.activePost.id,
            questionCount: this.activeForm.questionCount,
            type: Object.keys(this.modeEnum).filter(item => {
              return this.modeEnum[item] === this.activeForm.mode
            })[0]
          }
          if (this.activeForm.mode === 2) {
            payload.questionTypeCount = {
              '0': this.activeForm.trueOrFalse,
              '1': this.activeForm.singleChoice,
              '2': this.activeForm.multipleChoice,
              '3': this.activeForm.fillInBlank
            }
          }
          questionApi.getAbilityQuestionCountByPostId(payload).then(res => {
            if (res.code === 0) {
              //保存提交表单和生成的技能设置
              this.postData = this.postData.map(item => {
                if (item.id === payload.postId) {
                  //表单
                  item.form = {
                    difficulty: payload.difficulty,
                    mode: this.modeEnum[payload.type],
                    questionCount: payload.questionCount,
                    code: payload.codeQuestionCount,
                    trueOrFalse: this.activeForm.mode === 1 ? 0 : payload.questionTypeCount['0'],
                    singleChoice: this.activeForm.mode === 1 ? 0 : payload.questionTypeCount['1'],
                    multipleChoice: this.activeForm.mode === 1 ? 0 : payload.questionTypeCount['2'],
                    fillInBlank: this.activeForm.mode === 1 ? 0 : payload.questionTypeCount['3']
                  }
                  //技能列表
                  item.abilityList = res.res
                }
                return item
              })
              this.abilitySelection = res.res.map(item => {
                return { ...item, autoPaperType: payload.type }
              })
            }
          })
        }
      })
    },
    updatePostAbility() {
      //更新岗位右侧技能列表
      this.$refs.form.validate(valid => {
        if (valid) {
          //岗位下技能配置保存
          this.activePostAbility.questionCount = this.activeForm.questionCount
          this.activePostAbility.knowledgeIds = this.knowledgeSelection
          if (this.showCode) {
            this.activePostAbility.typeQuestionCount['5'] = this.activeForm.code
          } else {
            this.activePostAbility.typeQuestionCount = {
              '0': this.activeForm.trueOrFalse,
              '1': this.activeForm.singleChoice,
              '2': this.activeForm.multipleChoice,
              '3': this.activeForm.fillInBlank
            }
          }
          this.abilitySelection = this.abilitySelection.map(ability => {
            if (ability.abilityId === this.activePostAbility.abilityId) {
              ability = this.$deepCopy(this.activePostAbility)
            }
            return ability
          })
          this.saveAbilityList(this.abilitySelection)
        }
      })
    },
    addAbility() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let newItem
          if (this.activeForm.mode === this.modeEnum.AUTO) {
            //自动算法
            let payload = {
              codeQuestionCount: this.activeForm.code,
              difficulty: this.activeForm.difficulty,
              abilityId: this.activeAbility.id,
              questionCount: this.activeForm.questionCount,
              knowledgeIds: this.knowledgeSelection
            }
            questionApi.getAbilityQuestionCountByAbilityId(payload).then(res => {
              if (res.code === 0) {
                this.abilityData = this.abilityData.map(item => {
                  if (item.id === res.res.abilityId) {
                    item.isSelected = true
                    item.form = {
                      difficulty: res.res.difficulty,
                      questionCount: res.res.questionCount,
                      mode: this.modeEnum.ALLOCATED,
                      code: res.res.typeQuestionCount.hasOwnProperty('5') ? res.res.typeQuestionCount['5'] : 0,
                      trueOrFalse: res.res.typeQuestionCount.hasOwnProperty('0') ? res.res.typeQuestionCount['0'] : 0,
                      singleChoice: res.res.typeQuestionCount.hasOwnProperty('1') ? res.res.typeQuestionCount['1'] : 0,
                      multipleChoice: res.res.typeQuestionCount.hasOwnProperty('2') ? res.res.typeQuestionCount['2'] : 0,
                      fillInBlank: res.res.typeQuestionCount.hasOwnProperty('3') ? res.res.typeQuestionCount['3'] : 0
                    }
                    item.knowledgeIds = this.knowledgeSelection
                  }
                  return item
                })
                newItem = {
                  abilityId: this.activeAbility.id,
                  autoPaperType: 'AUTO',
                  abilityName: this.activeAbility.label,
                  difficulty: res.res.difficulty,
                  questionCount: res.res.questionCount,
                  typeQuestionCount: res.res.typeQuestionCount,
                  knowledgeIds: res.res.knowledgeIds
                }
                this.saveAbility(newItem)
              }
            })
          } else {
            this.abilityData = this.abilityData.map(item => {
              if (item.id === this.activeAbility.id) {
                item.isSelected = true
                item.form = this.activeForm
                item.knowledgeIds = this.knowledgeSelection
              }
              return item
            })
            newItem = {
              abilityId: this.activeAbility.id,
              autoPaperType: 'ALLOCATED',
              abilityName: this.activeAbility.label,
              difficulty: this.activeForm.difficulty,
              questionCount: this.activeForm.questionCount,
              typeQuestionCount: {
                '0': this.activeForm.trueOrFalse,
                '1': this.activeForm.singleChoice,
                '2': this.activeForm.multipleChoice,
                '3': this.activeForm.fillInBlank,
                '5': this.activeForm.code
              },
              knowledgeIds: this.knowledgeSelection
            }
            this.saveAbility(newItem)
          }
        }
      })
    },
    checkPostAbility(data) {
      //查看右侧技能配置
      this.activePostAbility = data
      this.activeName.label = '技能' //当前标题
      this.activeName.value = data.abilityName
      this.saveForm(this.activeForm.mode) //保存岗位表单
      this.$refs.form.clearValidate()
      this.activeForm = {
        difficulty: data.difficulty,
        mode: this.activeForm.mode,
        questionCount: data.questionCount,
        code: data.typeQuestionCount.hasOwnProperty('5') ? data.typeQuestionCount['5'] : 0,
        trueOrFalse: data.typeQuestionCount.hasOwnProperty('0') ? data.typeQuestionCount['0'] : 0,
        singleChoice: data.typeQuestionCount.hasOwnProperty('1') ? data.typeQuestionCount['1'] : 0,
        multipleChoice: data.typeQuestionCount.hasOwnProperty('2') ? data.typeQuestionCount['2'] : 0,
        fillInBlank: data.typeQuestionCount.hasOwnProperty('3') ? data.typeQuestionCount['3'] : 0
      }
      this.getKnowledgeSimple(data.abilityId)
      this.knowledgeSelection = this.$deepCopy(data.knowledgeIds)
    },
    checkAbility(data) {
      //技能模式下查看右侧技能配置
      this.activeAbility = this.abilityData.filter(item => {
        return item.id === data.abilityId
      })[0]
      this.activeName.value = data.abilityName
      this.activeForm = this.activeAbility.form
      this.$refs.tree.setCurrentKey(data.abilityId)
      this.getKnowledgeSimple(data.abilityId)
    },
    deleteAbility(index, id) {
      //删除技能配置
      this.abilitySelection.splice(index, 1)
      this.abilityData = this.abilityData.map(item => {
        if (item.id === id) {
          item.isSelected = false
        }
        return item
      })
    },
    createTest() {
      if (this.totalQuestionCount === 0) {
        this.$message.warning('题目数量不能为0')
        return
      }
      this.createLoading = true
      autoQuestionApi
        .getQuestionsByPostId({ abilityQuestionDTOList: this.abilitySelection, postId: this.activePost.id })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('自动出卷成功')
            this.$store.commit('question/reset')
            this.handleSelectAll({
              questionList: res.res.questionVOList,
              isClassify: false,
              currentI: this.getEditorData.currentIndex
            })
            this.abilitySelection = []
            this.$router.push({
              path: '/manage/paper/preview',
              query: {
                ruleId: res.res.ruleId,
                bankId: this.$route.query.bankId,
                IsAuto: true
              }
            })
          }
        })
        .finally(() => {
          this.createLoading = false
        })
    },
    //utils
    saveForm(mode) {
      //保存当前表单
      if (mode === 1) {
        this.autoForm = this.activeForm
      } else {
        this.allocatedForm = this.activeForm
      }
    },
    resetForm() {
      //重置表单
      this.allocatedForm = { ...this.defaultForm, mode: this.modeEnum.ALLOCATED }
      this.autoForm = { ...this.defaultForm, mode: this.modeEnum.AUTO }
    },
    computedQuestionCount() {
      //计算总题目数
      return this.activeForm.trueOrFalse + this.activeForm.singleChoice + this.activeForm.multipleChoice + this.activeForm.fillInBlank
    },
    saveAbility(newItem) {
      //判断是否新增
      let hadJoin = this.abilitySelection.some(item => {
        return item.abilityId === this.activeAbility.id
      })
      if (hadJoin) {
        //更新
        this.abilitySelection = this.abilitySelection.map(item => {
          if (item.abilityId === this.activeAbility.id) {
            item.difficulty = this.activeForm.difficulty
            item.questionCount = this.activeForm.questionCount
          }
          return item
        })
      } else {
        //新增
        this.abilitySelection.push(newItem)
      }
    },
    saveAbilityList(list) {
      //保存技能设置
      this.postData = this.postData.map(item => {
        if (item.id === this.activePost.id) {
          //技能列表
          item.abilityList = list
        }
        return item
      })
    },
    resetMenu() {
      this.activeAbility = {}
      this.activePost = {}
      this.activeForm = {}
      this.activePostAbility = {}
      this.abilitySelection = []
      this.questionTypeNum = {
        codeNum: 0,
        fillInBlankNum: 0,
        multipleChoiceNum: 0,
        singleChoiceNum: 0,
        subjectiveNum: 0,
        totalNum: 0,
        trueOrFalseNum: 0
      }
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
  .yt-container {
    height: 100%;
  }
  .yt-content {
    padding: 0;
    position: relative;
  }
}
.search-wrapper {
  .flexStyle();
  margin: 12px 0 7px;
  .yt-search {
    margin: 0 !important;
  }
  svg {
    font-size: @default;
    margin-right: 12px;
    fill: #707070;
  }
}
.knowledge-list-wrapper {
  width: 100%;
  padding: 10px 10px 0;
  background-color: #fff;
  position: absolute;
  top: 0;
  &.active {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 100;
  }
  .knowledge-list {
    .flexStyle(flex, flex-start);
    flex-wrap: wrap;
    height: 32px;
    margin-bottom: 10px;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    &.active {
      height: auto;
      max-height: 292px;
      overflow-y: auto;
    }
    .item {
      max-width: 160px;
      height: 32px;
      line-height: 30px;
      color: #999999;
      border: 1px solid #d9d9d9;
      padding: 0 25px;
      margin: 0 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.active {
        border-color: #448bff;
        color: #448bff;
      }
    }
    .checked {
      width: 15px;
      height: 15px;
      border-radius: 0 0 4px 0;
      position: absolute;
      right: 0;
      bottom: 0;
      .triangle {
        width: 0;
        height: 0;
        border-bottom: 15px solid #448bff;
        border-left: 15px solid transparent;
      }
      svg {
        font-size: 11px;
        fill: #ffffff;
        position: absolute;
        bottom: -1px;
        right: -1px;
      }
    }
  }
  p {
    width: calc(100% - 40px);
    margin-left: 10px;
    margin-bottom: 3px;
    color: #333;
    font-size: @small;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.active {
      white-space: pre-wrap;
      text-overflow: initial;
    }
  }
  .pack-up-button {
    .flexStyle(flex);
    flex-direction: column;
    svg {
      font-size: 16px;
      fill: #888888;
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}
.generation-container {
  .flexStyle(flex, flex-end);
  &.has-knowledge {
    margin-top: 78px;
    padding-top: 10px;
    height: calc(100% - 78px);
  }
  width: 100%;
  height: 100%;
  background-color: #f3f4f7;
}
.ability-form {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px 10px 0;
  .yt-form {
    margin-top: calc(100vh * 25 / 800);
    height: calc(100% - (100vh * 25 / 800) * 2 - 84px);
    overflow-y: auto;
    .el-form-item {
      .el-input-number {
        width: auto;
      }
      ::v-deep .el-input {
        max-width: 320px;
        width: auto;
        min-width: 290px;
        .el-input__inner {
          height: 38px;
          text-align: left;
        }
      }
      span {
        font-size: @default;
        color: #448bff;
        margin-left: 12px;
      }
      svg {
        font-size: @default;
        fill: #999999;
        margin-left: 6px;
      }
      &.form-item-mode {
        height: 20px;
        ::v-deep .el-form-item__label {
          line-height: 20px;
        }
        ::v-deep .el-form-item__content {
          line-height: 20px;
          .el-radio-group {
            .flexStyle(flex, flex-start);
            .el-radio {
              .flexStyle();
              height: 20px;
              line-height: 20px;
              margin-right: 21px;
              svg {
                margin-left: 3px;
              }
              .el-radio__label {
                .flexStyle();
                padding-left: 6px;
              }
            }
            .el-radio__input {
              .flexStyle();
            }
          }
        }
      }
    }
    .supply-tip {
      color: #448bff;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 100%;
      left: 0;
      font-size: @small;
    }
    .code-border {
      position: relative;
      &:before {
        width: 100%;
        height: 1px;
        background-color: #e2e4e8;
        position: absolute;
        top: -20px;
      }
    }
  }
  .el-button {
    width: 140px;
  }
}
.ability-cart {
  width: 300px;
  height: 100%;
  padding: 20px 1% 0 2%;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  .ability-header {
    justify-content: space-between;
    margin-left: 9px;
    p:before {
      background-color: #ff5050;
    }
  }
  .ability-selection {
    margin-top: calc(100vh * 15 / 800);
    height: calc(100% - (100vh * 25 / 800) * 2 - 84px);
    overflow-y: auto;
    .item {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-right: 5px;
      font-size: @medium;
      border-bottom: 1px solid #e2e4e8;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &.active {
        background-color: #f0f0f0;
      }
      div {
        .flexStyle(flex, space-between);
        padding: 0 10px;
        p {
          span {
            display: block;
            max-width: 60px;
            padding: 1px 8px;
            background: #dae9fc;
            border-radius: 10px;
            font-size: @small;
            color: #448bff;
          }
        }
        .title {
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          margin-left: 8px;
          fill: #999999;
          &:hover {
            fill: #ff5050;
          }
        }
        span {
          color: #333;
        }
      }
    }
  }
  .el-button {
    width: 100px;
  }
}
.ability-header {
  .flexStyle(flex, flex-start);
  margin-left: 19px;
  p {
    .flexStyle(flex);
    height: 20px;
    line-height: 20px;
    font-size: @medium;
    font-weight: bold;
    position: relative;
    &:before {
      content: '';
      width: 3px;
      height: 12px;
      background-color: #448bff;
      border-radius: 3px;
      position: absolute;
      left: -9px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
    span {
      display: inline-block;
      max-width: 125px;
      margin-left: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.confirm-button {
  text-align: center;
  margin-top: calc(100vh * 25 / 800);
  .el-button {
    height: 38px;
  }
}
.empty-container {
  //缺省样式
  .flexStyle();
  flex-direction: column;
  width: 250px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  color: #666666;
  span {
    color: #448bff;
    cursor: pointer;
  }
}
@media screen and (min-width: 1440px) and (min-height: 800px) {
  .ability-form {
    .yt-form {
      height: calc(100% - 163px);
      margin-top: 40px;
      .el-form-item {
        ::v-deep .el-input {
          width: 350px;
        }
      }
    }
  }
  .ability-cart {
    flex-shrink: 0;
    padding: 20px 10px 0 20px;
    .ability-header {
      justify-content: flex-start;
      margin-left: 19px;
      p:last-of-type {
        margin-left: 80px;
      }
    }
    .ability-selection {
      margin-top: 40px;
      height: calc(100% - 163px);
    }
  }
  .confirm-button {
    margin-top: 40px;
  }
}
</style>
